
const defaultHeaders = new Headers();
defaultHeaders.append('Content-Type', 'application/json');

async function request(method, url, params, token, isFormData = false) {
    let headers = defaultHeaders;
  
    if (token) {
      headers = new Headers(isFormData ? {} : defaultHeaders);
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (url.includes('Token')) {
        headers = new Headers(isFormData ? {} : defaultHeaders);
        headers.set('login-source', 'auction');
      }
  
    let body;
  
    if (params && !isFormData) {
      body = JSON.stringify(params);
    }
    if (params && isFormData) {
      body = params;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SIMULCAST_API_URL}${url}`, { headers, method, body });
        
        return response;
    } catch (error) {
        return {error}
    }

    // return {error};
}

// export const generateQueryString = (paramsObject, filteredItems) => {
//     return `?${filteredItems.map(param => `${param}`).join('+')}`;
// }

export const generateQueryString = paramsObject => `?${Object.keys(paramsObject).map(param => `${param}=${paramsObject[param]}`).join('&')}`;

export const generateSearchParams = params => {
    const defaultParams = {
        searchString: '',
        page_number: '',
        page_size: '',
        models: '',
        makes: '',
        years: '',
        categories: '',
        locations: '',
        families: '',
    };

    const mergedParams = { ...defaultParams, ...params };

    return generateQueryString(mergedParams);
};

export const generateLookupParams = params => {
    const defaultParams = {
        auctionType: false,
        itemCategory: false,
        imageCategory: false,
        itemYear: false,
        itemMake: false,
        itemModel: false,
        competitor: false,
        contactType: false,
        event: false,
        location: false,
        motivationType: false,
        meterType: false,
        packageOwner: false,
    };

    const mergedParams = { ...defaultParams, ...params };

    return generateQueryString(mergedParams);
};

export const parseQueryString = paramsString => JSON.parse(paramsString ?
    // eslint-disable-next-line no-div-regex
    '{"' + paramsString.replace('?', '').replace(/&/g, '","').replace(/=/g, '":"') + '"}' : '{}',
);

export const getFromSimulcast = async (url, token) => request('GET', url, null, token);
export const createFromSimulcast = async (url, params, token, isFormData) => request('POST', url, params, token, isFormData);
export const updateFromSimulcast = async (url, params, token) => request('PUT', url, params, token);
export const removeFromSimulcast = async (url, params, token) => request('DELETE', url, params, token);
