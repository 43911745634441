import React from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import Slider from "react-slick";
import { addToWatchList } from "../../../reducers/watchlist";
import { getAuctionItem, getPhotoGallery } from "../../../reducers/auction";
import { push } from "react-router-redux";
import ReactTooltip from "react-tooltip";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";

const RecentlyViewed = (props) => {
	const { recent = [], handlePathChoice } = props;

	const settings = {
		infinite: true,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		responsive: [
			{
				breakpoint: 1367,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const getDisplayDetails = (item) => {
		switch (item.display_ID) {
			case 1:
				return "Coming Soon";
			case 2:
				return "Buy Now";
			case 3:
				return "Prebid";
			case 4:
				return "Pending Sale";
			case 5:
				return "Live Now";
			case 6:
				return "Sold";
			case 7:
				return "Did Not Sell";
			case 8:
				// return "ProxiBid";
				return "Simulcast";
			default:
				break;
		}
	};

	const handleCharacterCount = (description) => {
		const charCount = description.length;
		const isMediumRes = window.innerWidth > 576 && window.innerWidth <= 1024;
		const isMobileRes = window.innerWidth < 576;

		if (charCount > 35 && charCount < 40) {
			return <h3 className="no-margin-top font-size-20">{description}</h3>;
		} else if (charCount > 40 && !isMediumRes) {
			return <h3 className={`no-margin-top font-size-16`}>{description.slice(0, 80)+"...."}</h3>;
		} else if (charCount > 40 && isMediumRes) {
			return <h3 className={`no-margin-top font-size-16`}>{description}</h3>;
		} else if (charCount > 40 && isMobileRes) {
			return <h3 className={`no-margin-top font-size-19`}>{description}</h3>;
		} else {
			return <h3 className="no-margin-top">{description}</h3>;
		}
	};

	const renderRecent = (recent) =>
		recent.map((item, idx) => (
			<Col key={idx} className="itemCard">
				<Link onClick={() => handlePathChoice(item)} to="#">
					<div className="img-wrapper pointer">
						<ProgressiveImage
							src={item.itemURL}
							placeholder={`${process.env.PUBLIC_URL}/assets/images/placeholder_spinner.gif`}>
							{(src, loading) => (
								<img
									style={{ opacity: loading ? 0.8 : 1 }}
									className="img-fluid featured"
									src={
										item.itemURL === "" || item.itemURL === null
											? `${process.env.PUBLIC_URL}/assets/images/no-image.png`
											: src
									}
									alt="item"
								/>
							)}
						</ProgressiveImage>
					</div>
					<div className="info">
						<div className="floating-cta">{getDisplayDetails(item)}</div>
						<div className="infoContainer">
							{handleCharacterCount(item.description)}
							<section className="meterReading">
								<div>
									<p>LOCATION</p>
									{item.meter !== "" ? <p>METER</p> : <p>&nbsp;</p>}
								</div>
								<div>
									<p>{item.location}</p>
									{item.meter !== "" ? <p>{item.meter}</p> : <p>&nbsp;</p>}
								</div>
							</section>
						</div>
						<section className="buyingContainer saleDescription pointer">
							<h4 className="pricingSection">View Details</h4>
						</section>
					</div>
				</Link>
			</Col>
		));

	return (
		recent.length > 5 && (
			<React.Fragment>
				<ReactTooltip place="top" type="dark" effect="float" />
				<header className="header">
					<h2 className="gray">previously viewed</h2>
				</header>
				<hr />
				<Slider {...settings} className="slide-3">
					{renderRecent(recent)}
				</Slider>
			</React.Fragment>
		)
	);
};

const mapStateToProps = (state) => ({
	recent: state.main.recentlyViewed,
});

const mapDispatchToProps = { addToWatchList, push, getAuctionItem, getPhotoGallery };

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyViewed);
